import {Homeowner} from "types";
import {Typography} from "@mui/material";
import * as React from "react";
import 'components/ListItem.css';

export const ListItem = (props: {date: string; homeowner: Homeowner, overridden?: boolean}) => {
    return (
        <div className="item-container">
            <div className={props.overridden ? 'item overridden' : 'item'}>
                <div className={`badge badge-${props.homeowner.address.streetNumber}`}>
                    {props.homeowner.address.streetNumber}
                </div>
                <Typography sx={{fontSize: 12}}>
                    {props.homeowner.names}
                </Typography>
                <Typography sx={{fontSize: 11, textAlign: 'right'}} fontWeight={100}>
                    {props.date}
                </Typography>
            </div>
            {props.overridden && (
                <div className="line-through"></div>
            )}
        </div>

    )
}
