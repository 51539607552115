import moment from "moment";
import {CalendarEntry} from "types";
import {FIRST_TRASH_DAY, Homeowners} from "config";

export const getTrashSchedule = () => {
    const scheduleStartDate = moment().subtract(1, "days");
    const scheduleEndDate = moment().isoWeekday(2).add(12, "months");

    const result: moment.Moment[] = [];
    const current = FIRST_TRASH_DAY.clone();

    while (current.isBefore(scheduleEndDate)) {
        result.push(current.clone());
        current.add(7, "days");
    }

    return result.reduce<CalendarEntry[]>((acc, entry, index) => {
        const homeowner = Homeowners[index % Homeowners.length];
        const [date, monthLong, monthShort, day, year] = [
            entry.format('ll'),
            entry.format('MMMM'),
            entry.format('MMM'),
            entry.format('D'),
            entry.format('Y')
        ];

        if (entry.isAfter(scheduleStartDate)) {
            acc.push({homeowner, date, monthLong, monthShort, day, year});
        }

        return acc;
    }, []);
}
