import {overrides} from 'config';
import * as React from 'react';
import {Box} from "@mui/material";
import {Header} from "components/Header";
import {ListItem} from "components/ListItem";
import {ListLabel} from "components/ListLabel";
import {getTrashSchedule} from "utils/getTrashSchedule";

export default function App() {
    const [nextDate, ...schedule] = getTrashSchedule();

    return (
        <Box sx={{display: 'grid', padding: 0, height: '100%', overflow: 'hidden'}}>
            {overrides[nextDate.date] ? (
                <Header nextDate={{
                    ...nextDate,
                    homeowner: overrides[nextDate.date],
                }} />
            ) : (
                <Header nextDate={nextDate} />
            )}
            <main>
                {schedule.map(({homeowner, date, monthLong, year}, i) => (
                    <>
                        {(i === 0 || (schedule[i - 1].monthLong !== monthLong)) && (
                            <ListLabel>{`${monthLong} ${year}`}</ListLabel>
                        )}
                        <ListItem
                            homeowner={overrides[date] ?? homeowner}
                            date={date}
                        />
                    </>
                ))}
            </main>
        </Box>
    );
}

