import React from 'react';
import 'styles/index.css';
import App from './App';
import teal from '@mui/material/colors/deepPurple';

import ReactDOM from 'react-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({palette: {mode: 'dark', primary: teal}});

function Apps() {
    return <ThemeProvider theme={theme}><App /></ThemeProvider>;
}

ReactDOM.render(<Apps />, document.querySelector('#root'));
