import * as React from 'react';
import 'components/ListLabel.css';

export const ListLabel = (props: {children: string}) => {
    return (
        <div className="month-label">
            {props.children}
        </div>
    )
}
