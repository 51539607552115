import * as React from "react";
import 'components/CalendarIcon.css';

export const CalendarIcon = ({month, date}) => {
    return (
        <div className="calendar-icon">
            <div>{month}</div>
            <div>{date}</div>
        </div>
    )
}
