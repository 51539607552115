import * as React from 'react';
import {CalendarEntry} from "types";
import {CalendarIcon} from "./CalendarIcon";
import {IconButton, Typography} from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import 'components/Header.css';

export const Header = ({nextDate}: { nextDate: CalendarEntry }) => {
    return (
        <header>
            <CalendarIcon
                month={nextDate.monthShort}
                date={nextDate.day}
            />
            <div>
                <div>
                    <Typography  sx={{fontSize: 14, marginTop: '4px', lineHeight: 1}}>
                        {nextDate.homeowner.names}
                    </Typography>
                    <Typography sx={{fontSize: 12, color: 'var(--text-secondary)'}} >
                        {nextDate.homeowner.address.streetNumber} {nextDate.homeowner.address.streetName}
                    </Typography>
                </div>
                <div>
                    <IconButton href="/cal.ics" sx={{color: 'var(--text-secondary)'}}>
                        <DateRangeIcon/>
                    </IconButton>
                </div>
            </div>
        </header>
    )
}
