export enum StreetName {
    SCHOOL_ST = 'School St',
    THIRTY_FIFTH_AVE = '35th Ave',
}

export type Address = {
    streetNumber: number;
    streetName: StreetName;
}

export type Homeowner = {
    names: string;
    address: Address
}

export type CalendarEntry = {
    homeowner: Homeowner;
    date: string,
    monthLong: string,
    monthShort: string,
    day: string,
    year: string
}
